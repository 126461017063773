:root.light {
    --background: linear-gradient(45deg, #4c7656, #b1cdb8);

    --background-default: #FFF;
    --color-default: #000;

    --color-active: #FFF;
    --background-active: #3943B7;
    --background-action: #E08E45;

    --background-light: rgba(255,255,255,0.4);
    --background-lighter: rgba(255,255,255,0.6);
    --background-dark: rgba(0,0,0,0.1);
    --background-darker: rgba(0,0,0,0.2);

}
:root.dark {
    --background: linear-gradient(45deg, #1e2c22, #213526);
    
    --background-default: #000;
    --background-dark: #555;
    --color-default: #FFF;

    --color-active: #FFF;
    --background-active: #3943B7;
    --background-action: #E08E45;
    
    --background-light: rgba(255,255,255,0.1);
    --background-lighter: rgba(255,255,255,0.2);
    --background-dark: rgba(0,0,0,0.2);
    --background-darker: rgba(0,0,0,0.4);
}
 
i {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;  
}
a {
    color: inherit;
}
b {
    font-weight: 700;
}
html, body, input, textarea {
    background: var(--background);
    color: var(--color-default);
}
#tables {
    display: none;
}
body {
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

button {
    display: flex;
    background-color: var(--background-lighter);
    color: var(--color-default);
    justify-content: center;
    align-items: center;
    padding: 0.5em 0.5em;
    cursor: pointer;

    i {
        vertical-align: top;
        padding-right: 0.5em;
    }
    &:hover {
        background-color: var(--background-light);
    }
}
#keyboard {
    display: none;
    flex-direction: column;
    font-size: 1em;
    gap: 3px;
    padding: 0.5em;
    background: var(--background);
    
    &.show {
        display: flex;
    }
    > .row {
        display: flex;
        gap: 3px;
        > .key {
            display: block;
            line-height: 3em;
            text-align: center;
            width: 3em;
            padding: 0;
            border-radius: 0.25em;
            i {
                padding: 0;
                vertical-align: middle;
            }
        }
    }
}
#login {
    &.hide {
        display: none;
    }

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    color: var(--color-default);
    background: var(--background-default);

    > img {
        display: block;
        margin: 0 auto;
        width: 192px;
        height: 192px;
        transition: width 1s ease-out, height 1s ease-out;
        animation-name: fade-in-out;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in;
    }

    > .login {
        opacity: 0;
        height: 0;
        overflow: hidden;
        width: 15em;

        .error {
            opacity: 0;
            height: 0;
            overflow: hidden;
            text-align: center;
            background: rgb(90,0,0);
            transition: height .25s linear, opacity .25s linear;

            &.show {
                opacity: 1;
                height: auto;
                padding: 0.5em;
            }
        }

        input, button {
            border: 1px solid var(--background-dark);
            background: var(--background-lighter);
            border-radius: 0.5em;
            box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
            margin-bottom: 0.5em;
            padding: 0.5em;
        }

        label {
            display: block;
            position: relative;
            margin-top: 1em;

            span {
                position: absolute;
                bottom: 0;
                z-index: 3;
                padding: 0.5em;
                cursor: text;
                transition: transform .2s ease;
            }

            input {
                width: 100%;
                box-sizing: border-box;
            }

            input:valid + span {
                transform: translate(-100%,0) translate(-0.5em,0);
                cursor: pointer;
            }
        }

        button {
            margin-top: 1em;
            padding: 0.5em;
            width: 100%;
            background: var(--background-dark);
        }
        > button:hover {
            background: var(--background-darker);
        }
    }

    > .text {
        opacity: 1;
        height: auto;
        overflow: visible;

        h1 {
            text-align: center;
            font-size: 1.75em;
        }

        h2 {
            text-align: center;
            font-size: 1.25em;
        }

        h3 {
            font-size: 1.25em;
        }
    }

    &.show-login {
        > .login {
            opacity: 1;
            height: auto;
            overflow: visible;
            transition: height .25s linear, opacity .25s linear;
        }

        > .text {
            opacity: 0;
            height: 0;
            overflow: hidden;
        }

        > img {
            width: 96px;
            height: 96px;
            animation: none;
        }
    }
}

#login.startup > .startup,
#login.login > .login {
    opacity: 1;
    height: auto;
    overflow: visible;
}

#site {
    position: relative;
    display: flex;
    flex: 1;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.125) 0px 6px 10px;
    overflow: hidden;
    margin: 2em;
    background-color: var(--background-light);
    &.hide {
        display: none;
    }
}

#order {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 25rem;

    .lines {
        flex: 1;
        overflow: auto;
        dl {
            padding: 0.5rem;
            cursor: pointer;

            dt {
                font-weight: 700;

                .price {
                    float: right;
                }
            }
            dd {
                margin-left: 0.5rem;

                &.small {
                    font-size: 0.75em;
                }
            }

            &.active {
                color: var(--color-active);
                background-color: var(--background-active);
            }
        }
    }
    .total {
        border-top: 1px solid var(--background-dark);
        padding: 0.5em;

        dl {
            text-align: right;

            dt {
                font-weight: 700;
                font-size: 1.25em;
            }
        }
    }
    .calculator {
        border-top: 1px solid var(--background-dark);
        background: var(--background-dark);
        padding: 0.5em;
        
        .buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.25em;
            margin-bottom: 0.5em;
            border-radius: 0.5em;
            overflow: hidden;
            
            button {
                flex: 1;
                border-radius: 0.25em;
                background: var(--background-lighter);
            }
            button:hover {
                background: var(--background-light);
            }
        }
        .split-x {
            .pay {
                overflow: hidden;
            }
            .numpad {
                overflow: hidden;
            }
        }
        .split-x {
            display: flex;
            flex-direction: row;
            gap: 0.25em;
            border-radius: 0.5em;
            overflow: hidden;

            .pay {
                flex: 4;
                display: flex;
                border-radius: 0.25em;
                
                button {
                    flex: 1;
                }
            }
            .numpad {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 1px;
                border-radius: 0.25em;

                flex: 6;

                font-weight: 700;
    
            }
            button {
                flex: 1 0 calc(25% - 1px);
                line-height: 3em;
                padding: 0;
                background: var(--background-lighter);

                &.active {
                    background-color: var(--background-action);
                }
            }
            button:hover {
                background: var(--background-light);
            }
        }
    }
}

#pages {
    flex: 1;
    flex-direction: column;
    margin: 0.5em;
    border-radius: 0.5em;
    overflow: hidden;
    background: var(--background-light);
    box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;

    > .location {
        display: flex;
        border-bottom: 1px solid var(--background-dark);
        > .history {
            display: flex;
            flex: 1;
            > button {
                display: flex;
                background: var(--background-dark);
                > i {
                    font-size: 1.5em;
                    vertical-align: sub;
                    line-height: 2.5rem;
                }
                position: relative;
                padding: 0 1em 0 1em;
                line-height: 2.5rem;
                margin-right: -0.5em;

                clip-path: polygon(0 0,calc(100% - 10px) 0,100% 50%,calc(100% - 10px) 100%,0 100%,10px 50%);
                &:first-child {
                    padding-left: 0.5em;
                    padding-right: 0.5em;
                    clip-path: polygon(0 0,calc(100% - 10px) 0,100% 50%,calc(100% - 10px) 100%,0 100%);
                }
                &.active,
                &:hover {
                    background: var(--background-darker);
                }
            }
        }
        > .close {
            display: none;
            background: var(--background-dark);
            i {
                padding: 0;
            }
        }
    }

    > .page {
        flex: 1;
        display: none;
        padding: 0.5em;
        flex-direction: column;
        overflow: auto;

        &.show {
            display: flex;
        }
    }
}
#site.pages {
    #pages {
        display: flex;
    }
}

.element-checkbox {
    cursor: pointer;
    i {
        font-size: 2em;
        vertical-align: middle;
        padding-right: 0.125em;
    }
    label {
        vertical-align: middle;
        cursor: pointer;
    }
}

.element-text {
    position: relative;
    display: flex;
    height: 2.25em;
    border: 1px solid var(--background-dark);
    background: var(--background-lighter);
    padding: 0 0.25em;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
    margin-bottom: 0.5em;
    i {
        position: absolute;
        font-size: 1.5em;
        line-height: 1.5em;
    }
    .input {
        flex: 1;
        line-height: 2.25em;
        padding: 0 0.5em;

    }
    i + .input {
        padding-left: 2em;
    }
    label {
        position: absolute;
        top: -1.25em;
        left: 0;
    }
    &:has(label) {
        margin-top: 1.5em;
    }
}
.element-dropdown {
    position: relative;
    margin-bottom: 0.5em;
    
    label {

    }
    .text {
        display: flex;

        padding: 0 0.25em;
        border: 1px solid var(--background-dark);
        background: var(--background-lighter);
        border-radius: 0.5em;
        box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
        .input {
            flex: 1;
            line-height: 2.25em;
            padding: 0 0.5em;
        }
        i {
            position: absolute;
            font-size: 1.5em;
            line-height: 1.5em;
        }
        i + .input {
            padding-left: 2em;
        }
        .input + i::before {
            content: 'expand_more';
        }
        .input + i {
            position: static;
        }
    }
    .results {
        position: absolute;

        left: 0;
        right: 0;
        padding: 0 0.25em;

        height: 0;
        overflow: hidden;
        z-index: 100;
    }
    &.active {
        .text {
            border-radius: 0.5em 0.5em 0 0;
            .input + i::before {
                content: 'expand_less';
            }
        }
        .results {
            height: 10em;
            overflow-y: auto;

            border: 1px solid var(--background-dark);
            border-top: 0px;

            background: var(--background-lighter);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            border-radius: 0 0 0.5em 0.5em;
            box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
        }
    }
}

.input {
    cursor: text;
    span {
        border-left: 1px solid transparent;
        margin-left: -1px;
    }
    &.active {
        .active {
            animation: cursor-blink 1.5s infinite;
        }
    }
}
.results {
    .item {
        cursor: pointer;
    }
}

.element-search {
    display: flex;
    flex: 1;
    overflow: hidden;

    .results {
        flex: 1;
        overflow-y: auto;
    }
}
.element-search[data-query=purchaseQuery] {
    .ordered {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        background-color: green;
        color: #FFF;
        padding: 0 0.25em;
        border-radius: 5px;


        i {
            font-size: 1.25em;
            vertical-align: sub;
        }
        span {
            font-weight: 700;
        }

        &:has(span:empty) {
            display: none;
        }
    }
}
.element-search[data-query=customerQuery] {

}


.list,
.grid {
    .state {
        .states {
            display: block;
            width: 1.5em;
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0px;
            overflow: hidden;
            transition: width 0s;
            transition-delay: 0s;
            .state-item {
                position: absolute;
                font-size: 0.8em;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0.4em;
                span {
                    display: none;
                    width: 0px;
                    font-size: 1em;
                }
                &:nth-child(1) {
                    z-index: 3
                }
                &:nth-child(2) {
                    z-index: 2;
                    left: 0.5em;
                }
                &:nth-child(3) {
                    z-index: 1;
                    left: 1em;
                }
            }
            &:hover {
                width: 12em;
                transition: width 0s;
                transition-delay: 0.5s;
                .state-item {
                    span {
                        display: block;
                        height: 1.2em;
                        line-height: 1.2em;
                        position: absolute;
                        width: 10em;
                        color: #FFF;
                        margin-left: 0.4em;
                        border-bottom: 1px solid #FFF;
                        border-top: 1px solid #FFF;
                    }
                    &:nth-child(1) span {
                        padding-left: 1.5em;
                        border-top: 0;
                    }
                    &:nth-child(2) span {
                        margin-top: 1.25em;
                        padding-left: 1em;
                    }
                    &:nth-child(2) span:nth-child(2) {
                        margin-top: 5em;
                    }
                    &:nth-child(3) span {
                        margin-top: 2.5em;
                        padding-left: 0.5em;
                    }
                }
            }
        }
    }
}

.sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;

    > button {
        padding: 1em;
        width: 10em;
        flex-direction: column;
        gap: 1em;
        border-radius: 0.5em;
        background: var(--background-light);
        box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
        &:hover {
            background: var(--background-lighter);
        }
        i {
            display: block;
            font-size: 4em;
            text-align: center;
            padding: 0;
        }
        span {
            
        }
    }
}

.fieldsets {
    flex: 1;
    &.fieldset-columns {
        column-count: auto;
        column-gap: 1em;
        column-width: 18em;
    }

    .devider {
        break-inside: avoid-column;
        padding-bottom: 1em;
    }

    fieldset {
        position: relative;

        padding: 1em 0.5em 0.5em 0.5em;
        background: var(--background-light);
        box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
        border-radius: 0.5em;

        > legend {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background: var(--background-default);
            padding: 0 0.5em;
            border-radius: 0 0 0.5em 0.5em;
            box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
        }
    }
}

.button-bar {
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;

    button {
        box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
        border-radius: 0.5em;
    }
}

.grid {
    position: relative;
    font-size: 1rem;
    >div.page {
        position: absolute;
        width: 100%;

        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
    }

    div.item {
        flex-grow: 1;
        order: 2;
        z-index: 1;
        overflow: hidden;
        position: relative;

        display: flex;
        align-items: stretch;
        width: 10em;

        >div.container {
            margin: 0.25em;
            padding: 0.5em;
            background: var(--background-light);
            box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
            border-radius: 0.5em;
            overflow: hidden;
            flex-grow: 1;

            &.state {
                padding-left: 1.5em;
            }
            
            h2 {
                font-weight: 700;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 1.5em;
            }
            h3 {
                font-size: .75em;
                color: var(--color-default);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            dl {
                display: flex;
                font-size: .75em;
                flex-flow: row wrap;

                dt {
                    flex: 1 30%;
                    color: var(--color-default);
                    font-style: italic;
                    line-height: 1.5em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                dd {
                    flex: 1 70%;
                    line-height: 1.5em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .image {
                height: 12em;
                mix-blend-mode: multiply;
                margin: -0.5em -0.5em 0 -0.5em;
                border-bottom: 1px solid var(--background-dark);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            .split {
                display: flex;
                flex-flow: row nowrap;

                > div {
                    &.split-full {
                        flex: 1;
                    }
                    &.split-right {
                        text-align: right;
                    }
                }
            }
        }
    }
    div.spacer {
        flex-grow: 1;

        &.start {
            order: 1;
        }
        &.end {
            order: 3;
        }
    }
}

.list {
    position: relative;
    font-size: 1rem;
    >div.page {
        position: absolute;
        width: 100%;
    }
    .state {
        padding-left: 1.25em;
        .states {
            .state-item {
                top: 0.5em;
                bottom: 0.5em;
            }
        }
    }
    .right {
        position: absolute;
        right: 0em;
        top: 0em;
        display: flex;
        img {
            width: 48px;
            height: 48px;
        }
        .color-item {
            height: 48px;
            width: 0.4em;
            display: flex;
            flex-direction: column;
            >span {
                flex: 1;
            }
        }
    }
    dl {
        position: relative;
        padding: 0.5em 0 0.5em 0.75em;
        overflow: hidden;
        >dt {
            font-weight: 300;
            font-size: 1em;
            line-height: 1.5em;
            height: 1.5em;
            &.split-right {
                display: flex;
                span:nth-child(1) {
                    flex: 1;
                    overflow: hidden;
                }
                span:nth-child(2) {
                    width: auto;
                    padding: 0 0.5rem;
                }
            }
            &.item-right-top {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
            }
        }
        >dd {
            font-weight: 300;
            font-size: 0.75em;
            line-height: 1.25em;
            height: 1.25em;
        }
        >dt,
        dd {
            white-space: nowrap;
            &.pad {
                display: flex;
                div {
                    flex: 1;
                    overflow: hidden;
                    margin: 0 0.2em 0 0;
                    border-right: 1px solid var(--color-default);
                    white-space: nowrap;
                    &:last-child {
                        border: 0;
                    }
                    &.pad-2 {
                        flex: 2;
                    }
                    &.pad-4 {
                        flex: 4;
                    }
                    &.pad-6 {
                        flex: 6;
                    }
                }
                &.pad-transparent {
                    div {
                        border-right: 1px solid transparent;
                    }
                }
            }
            &.overflow {
                height: auto;
            }
            &.center {
                text-align: center;
            }
            label {
                padding: 0;
                &::after {
                    display: none;
                }
                > span.label {
                    display: none;
                }
            }
        }
        &.depth-1 {
            margin-left: 1.0em;
        }
        &.depth-2 {
            margin-left: 2.0em;
        }
        &.depth-3 {
            margin-left: 3.0em;
        }
        &.depth-4 {
            margin-left: 4.0em;
        }
        &.depth-5 {
            margin-left: 5.0em;
        }
        &.depth-6 {
            margin-left: 6.0em;
        }
    }
}

.template-default {

}

#dialog {
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    backdrop-filter: blur(20px);
    
    > .pages {
        > fieldset {
            width: 15em;
            position: relative;

            padding: 1.5em 1em 1em 1em;
            background: var(--background-light);
            box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
            border-radius: 0.5em;

            > legend {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                background: var(--background-default);
                padding: 0 0.5em;
                border-radius: 0 0 0.5em 0.5em;
                box-shadow: rgba(0, 0, 0, 0.075) 0px 4px 8px;
            }
        }
    }

    &.hide {
        display: none;
    }
}

@keyframes cursor-blink {
    0% {
        border-left: 1px solid var(--color-default);
    }
    49% {
        border-left: 1px solid var(--color-default);
    }
    50% {
        border-left: 1px solid transparent;
    }
    100% {
        border-left: 1px solid transparent;
    }
}
@keyframes fade-in-out{
    from{opacity:1}
    to{opacity:.4}
}
@media only screen and (min-width: 800px) {
    #keyboard {        
        position: absolute;
        bottom: 2em;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
    }
}
@media only screen and (max-width: 799px) {
    #site {
        flex: 1;
        margin: 0em;
        #pages {
            display: none;
            margin: 0;
            > .location {
                > .close {
                    display: block;
                }
            }
        }
        #order {
            flex: 1;
        }
    }
    #keyboard {

    }
    #receipt {
        width: auto;
    }

    #site.pages #order {
        display: none;
    }

    #login {
        .login {
            > button {
                margin-top: .5em;
            }

            > label {
                margin-top: 1.5em;
            }

            > label input:valid + span {
                transform: translate(0,-1.75em) translate(-.5em,0);
            }
        }
    }
}
@media only screen and (max-width: 369px) {
    html, body, input, textarea, select {
        font-size: 14px;
    }
}
@media only screen and (max-width: 319px) {
    html, body, input, textarea, select {
        font-size: 12px;
    }
}